import ExecommData from '../data/execomm.json'

export default function Execomm(){
    return(
        <div className="py-28 bg-gray-800 text-gray-100 mx-0">
            <h1 className="text-center text-2xl md:text-4xl font-medium mb-12 mx-8">IEEE Executive Committee 2023</h1>
            {ExecommData.map((team) => 
                <div className='mx-8 sm:mx-16 lg:mx-40'>
                    <h2 className='text-center text-xl md:text-3xl font-medium mb-8'>{team.title}</h2>
                    {/* <div className='mb-16 grid grid-cols-1 md:grid md:grid-cols-3 gap-12 justify-center'> */}
                    <div className='mb-16 flex flex-wrap justify-center'>
                        {team.data.map((member) => 
                            <div className='rounded-xl md:w-2/6 py-6 md:px-6 md:py-0'>
                                <div className='bg-gray-900 rounded-xl'>
                                    <img className="rounded-t-xl object-cover bg-black w-full aspect-square"
                                    src={process.env.PUBLIC_URL + "/images/execomm/" + member.name.split(" ").join("") + ".jpg"}
                                    alt={member.name}/>
                                    <div className='px-4 py-2 text-center'>
                                        <h3 className='md:text-lg font-medium'>{member.name}</h3>
                                        <h4 className='text-sm md:text-base text-gray-300'>{member.designation}</h4>
                                        <h4 className='text-sm md:text-base text-gray-300'>IEEE Number : {member.ieee}</h4>
                                    </div>
                                </div>
                                <br/>
                            </div> 
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}