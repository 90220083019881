import { NavLink } from "react-router-dom"

const siteMap = [
    {name:"About DA-IICT", href:"https://www.daiict.ac.in"},
    {name:"About IEEE", href:"https://www.ieee.org"},
    {name:"IEEE SB DA-IICT", href:"http://ieee.daiict.ac.in"},
    {name:"IEEE Gujarat Section", href:"https://ieeegujaratsection.org/"},
    
]

const chapters = [
    {name:"IAS", href:"chapters", section:"ias"},
    {name:"WIE", href:"chapters", section:"#wie"},
    {name:"MTT-S", href:"chapters", section:"#mtts"},
    {name:"SIG Humanitarian Technology", href:"chapters", section:"#sig-humanitarian-technology"},
    {name:"SIG Embedded", href:"chapters", section:"#sig-embedded"},
    {name:"SIG Mobile Apps", href:"chapters", section:"#sig-mobile-apps"},
    {name:"SIG Machine Intelligence", href:"chapters", section:"#sig-machine-intelligence"},
]

const events = [
    {name:"Summer School 2023", href:"http://ieee.daiict.ac.in/ss23"},
    {name:"Summer School 2022", href:"http://ieee.daiict.ac.in/ss22"},
    {name:"i.Fest 2022", href:"http://ifest.daiict.ac.in"},
    {name:"Tic-Tech-Toe 2022", href:"http://ieee.daiict.ac.in/tictechtoe22"},
]


export default function Footer() {
    return (     
    <footer class="py-5 shadow px-5 bg-gray-900">
        <div class="sm:grid sm:grid-cols-3 lg:flex items-stretch mb-6 justify-center sm:gap-x-12 md:gap-x-16 lg:gap-x-20">
            <a href="https://www.daiict.ac.in" class="sm:col-span-3 flex items-center my-1 sm:my-0 justify-center mx-5">
                <img src={process.env.PUBLIC_URL + "/images/da.png"} class="h-52" alt="DA-IICT Logo" />
            </a>
            <ul class="block my-5 text-md sm:my-4 text-gray-300 text-center lg:text-start">
            <li className="mb-1 sm:mb-2"><b>Site Map</b></li>
            {siteMap.map((item) => (
                <li className="mb-0 sm:mb-1">
                    <a
                    href={item.href}
                    className={
                        "hover:underline"
                    }>
                    {item.name}
                    </a>
                </li>
            ))}
            </ul>

            <ul class="block my-5 text-md sm:my-4 text-gray-300 text-center lg:text-start">
            <li className="mb-1 sm:mb-2"><b>Chapters</b></li>
            {chapters.map((item) => (
                <li className="mb-0 sm:mb-1">
                    <NavLink
                    to={{
                        pathname:item.href,
                        hash:item.section
                    }}
                    className={
                        "hover:underline"
                    }>
                    {item.name}
                    </NavLink>
                </li>
            ))}
            </ul>
            
            <ul class="block my-6 text-md sm:my-4 text-gray-300 text-center lg:text-start">
                <li className="mb-1 sm:mb-2"><b>Events</b></li>
                {events.map((item) => (
                    <li className="mb-0 sm:mb-1">
                        <a
                        href={item.href}
                        className={
                            "hover:underline"
                        }>
                        {item.name}
                        </a>
                    </li>
                ))}
                <div class="block col-span-3 my-6 text-md sm:mb-4 text-gray-300 text-center lg:text-start">
                    <div className="mb-2"><b>Connect With Us</b></div>
                    <div class="flex shrink items-center my-4 gap-4 justify-center">
                        <a href="https://www.instagram.com"><img className="h-7" src={process.env.PUBLIC_URL + "/images/insta.png"} alt="Instagram"/></a>
                        <a href="https://www.linkedin.com/company/ieeesbdaiict/"><img className="h-7" src={process.env.PUBLIC_URL + "/images/linkedin.png"} alt="LinkedIn"/></a>
                        <a href="https://www.youtube.com/@ifestda-iict2121"><img className="h-5" src={process.env.PUBLIC_URL + "/images/yt.png"} alt="Youtube"/></a>
                        <a href="mailto:ieee@daiict.ac.in"><img className="h-5" src={process.env.PUBLIC_URL + "/images/gmail.png"} alt="Email"/></a>
                    </div>
                </div>
            </ul>
        </div>
        <hr class="mx-auto border-gray-700" />
        <div class="mt-4 block text-sm text-gray-400 text-center text-gray-400">  
            © IEEE SB DAIICT 2023. All Rights Reserved | Designed by <a href="https://github.com/vishesh147" className="hover:text-cyan-400">Vishesh Patel</a>, IEEE SB, DA-IICT
        </div>
    </footer>
)}

