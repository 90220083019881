import GrantData from '../data/grant.json';
import {Link} from 'react-router-dom';
export default function Grant(){
    return (
        
        <div className="py-20 bg-gray-800 text-gray-100 mx-0">
            
            <h1 className="text-center md:text-4xl font-large mb-12 mx-8 my-10">Guidelines for Approving Grants for IEEE Conference</h1>
            {GrantData.map((Grant) => (
                <div>
                    
                    <div className="mb-16 grid grid-cols-1 md:grid md:grid-cols-1 mx-8 sm:mx-18 lg:mx-40 bg-gray-900 rounded-xl justify-center">
                        
                        <div className="m-10 sm:col-span-2">
                            <h1 className="flex text-center text-xl md:text-3xl font-medium mb-4">
                                {Grant.name}
                            </h1>
                            {Grant.content.map((para) => (
                                <p className='flex mb-6'>
                                    {para}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
            {/* <div className="flex py-2 bg-gray-800 text-gray-100 mx-0 justify-center">
           
                <a href = "C:\Users\91635\Desktop\IEEEWEBSITE\IEEE2023React\Grant_Application.docx" class="no-underline hover:underline ..." className="text-center text-2xl md:text-4xl font-large mb-12 mx-8 text-sky-400 ">1) Grant Application</a>
                <button type="button" class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center" onClick={(e) => { window.location.href=process.env.PUBLIC_URL + '/images/Grant_application.docx';
                }}>
                <svg class=" w-4 h-4 mr-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M13 8V2H7v6H2l8 8 8-8h-5zM0 18h20v2H0v-2z"/></svg>
                <span>Grant Application</span>
                </button>
            </div> */}
        </div>
    )
}