import HistoryData from '../data/history.json';
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import Achievements from '../data/homeAchievements.json'
import { useState, useEffect } from 'react';


export default function History() {
    const [value, setValue] = React.useState(0);
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    var percent = "60";
    if(windowSize[0] < 768){
        percent = "90";
    };
  
    
    return (
      <div  className='py-28 bg-gray-800 text-gray-100 mx-0 items-center md:pl-20'>
        <h1 className="text-center text-2xl md:text-4xl font-medium mb-6 mx-8">Our History</h1>
        <Box>
          <TabContext value={value}>
          <Box>
          <TabList className='pl-15px items-center' value={value} onChange={handleChange} centered = {true} textColor='inherit'variant='scrollable' visibleScrollbar = {true} allowScrollButtonsMobile = {true}>
            {HistoryData.map((yearData, i) => (
                <Tab label={yearData.year} value={i} />
            ))}
          </TabList>
          </Box>
            {HistoryData.map((yearData, i) => (
                <TabPanel value={i}>
                  <div className='lg:flex gap-8 py-3 bg-gray-800 text-gray-100 mx-2 sm:mx-12 lg:mx-40'>
                    <div className='pb-4 lg:pb-0 lg:w-1/2'>
                        <h2 className='text-base md:text-lg font-medium mb-2 w-full'>Office Bearers</h2>
                        {yearData.data.map((chapter) => (
                            <div className='mb-2'>
                                {/* <h2 className='text-base md:text-lg font-medium mb-2 w-full'>{chapter.title}</h2> */}
                                <div className='flex text-sm md:text-base'>
                                {/* <table className='table-auto'>
                                    {chapter.members.map((member) => (
                                    <tr className='text-sm md:text-base'>
                                        <td className='font-medium'>{member.designation}</td>
                                        <td className='text-center px-3'> : </td>
                                        <td className=''>{member.name}</td>
                                    </tr>
                                    ))}                                  
                                </table> */}
                                <ul className='list-disc ml-4'>
                                    {chapter.members.map((member) => (
                                    <li>{member.name}, {member.designation}, {chapter.title}</li>
                                    ))}                                  
                                </ul>
                                </div>
                            </div>
                        ))}
                    </div>
                                        
                    <div className='lg:pb-0 lg:w-1/2'>
                      <div className='pb-8'>
                        <br/>
                          <h2 className="flex text-base md:text-xl font-medium mb-8">
                              Achievements
                          </h2>
                          <Carousel autoPlay interval="3000" infiniteLoop centerMode centerSlidePercentage={percent}
                              showIndicators={false} showThumbs={false} showStatus={false}>
                              {Achievements.map((achievement) => (
                                  <div className='text-sm md:text-base flex items-center text-center rounded-xl mx-1 h-full p-6 bg-gray-900'>
                                      {achievement}
                                  </div>
                              ))}
                          </Carousel>
                      </div>

                      {/* <div className=''>
                          <h2 className="flex text-base md:text-lg font-medium mb-4">
                              Major Events
                          </h2>
                          <Carousel autoPlay interval="3000" infiniteLoop centerMode centerSlidePercentage={percent}
                              showIndicators={false} showThumbs={false} showStatus={false}>
                              {Achievements.map((achievement) => (
                                  <div className='text-sm md:text-base flex items-center text-center rounded-xl mx-1 h-full p-6 bg-gray-900'>
                                      {achievement}
                                  </div>
                              ))}
                          </Carousel>
                      </div> */}
                    </div>

                  </div>
                </TabPanel>
            ))}
          </TabContext>
        </Box>
      </div>
    );
  }