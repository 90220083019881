import { Disclosure } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom';

const navigation = [
  { name: 'Home', href: '/', current: true },
  { name: 'Chapters', href: '/chapters', current: false },
  { name: 'Events', href: '/events', current: false },
  { name: 'Gallery', href: '/gallery', current: false },
  { name: 'ExeComm', href: '/execomm', current: false },
  { name: 'Grant Application', href: '/grant-application', current: false },
  { name: 'Members', href: '/members', current: false },
  { name: 'Contact', href: '/contact', current: false },
  { name: 'History', href: '/history', current: false },
]

export default function Navbar() {
  return (
    <Disclosure as="nav" className="bg-gray-900/[.8] backdrop-blur-lg fixed w-full top-0 z-10">
      {({ open }) => (
        <>
          <div className="mx-auto max-w-7xl px-2 md:px-4">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 left-0 flex items-center lg:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-300 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-center justify-center lg:justify-start">
                <div className="flex flex-1 items-center justify-center lg:justify-start">
                  <img
                    className="block h-10 w-auto"
                    src={process.env.PUBLIC_URL + "/images/ieee.png"}
                    alt="IEEE"
                  />
                </div>
                <div className="hidden lg:ml-6 lg:block">
                  <div className="flex space-x-4">
                    {navigation.map((item) => (
                      <NavLink exact
                        to={item.href}
                        className={({ isActive }) =>
                          isActive ? "bg-gray-800 text-white px-2 py-2 rounded-md text-md font-medium" : 
                          'text-gray-300 hover:bg-gray-700 hover:text-white px-2 py-2 rounded-md text-md font-medium'
                        }
                      >
                        {item.name}
                      </NavLink>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Disclosure.Panel className="block lg:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {navigation.map((item) => (
                <div className='flex'>
                <NavLink exact
                to={item.href}
                className={({ isActive }) =>
                  isActive ? "flex flex-grow bg-gray-800 text-white px-2 py-2 rounded-md text-md font-medium" : 
                  'text-gray-300 hover:bg-gray-700 hover:text-white px-2 py-2 rounded-md text-md font-medium'
                }
                >
                {item.name}
                </NavLink>  
                </div>
                          
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
