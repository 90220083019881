import MemberData from '../data/members.json'

export default function Members(){
    return(
        <div className="py-28 bg-gray-800 text-gray-100 mx-0">
            <h1 className="text-center text-2xl md:text-4xl font-medium mb-12 mx-8">IEEE Members of DA-IICT</h1>
            <div className="flex justify-center mx-4 sm:mx-16 lg:mx-40 bg-gray-900 rounded-xl">
                <table className="table-auto w-full">
                    <thead className="hidden md:table-header-group">
                        <tr className="text-left border-b-2 border-gray-700">
                            <th className="py-2 px-4">#</th>
                            <th className="py-2 px-4">Name</th>
                            <th className="py-2 px-4">E-mail</th>
                            <th className="py-2 px-4">IEEE Number</th>
                        </tr>
                    </thead>
                    <tbody className="block md:table-row-group">
                    {MemberData.map((member, i) => (
                        <tr className="grid grid-cols-1 justify-center md:table-row py-2 text-start border-b-2 border-gray-800">
                            <td className="py-2 px-4 flex justify-between items-center md:table-cell">
                                <span className="text-sm sm:text-base font-medium md:hidden">#</span>
                                <span className="text-sm sm:text-base">{i+1}</span>
                            </td>
                            <td className="py-2 px-4 flex justify-between items-center md:table-cell">
                                <span className="text-sm sm:text-base font-medium md:hidden">Name</span>
                                <span className="text-sm sm:text-base">{member.name}</span>
                                
                            </td>
                            <td className="py-2 px-4 flex justify-between items-center md:table-cell">
                                <span className="text-sm sm:text-base font-medium md:hidden">Email</span>
                                <span className="text-sm sm:text-base">{member.email}</span>
                            </td>
                            <td className="py-2 px-4 flex justify-between items-center md:table-cell">
                                <span className="text-sm sm:text-base font-medium md:hidden">IEEE Number</span>
                                <span className="text-sm sm:text-base">{member.ieee}</span>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}