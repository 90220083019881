import Content1 from '../data/homeDA.json'
import Content2 from '../data/homeIEEE.json'
import List from '../data/homeList.json'
import Achievements from '../data/homeAchievements.json'
import bannerimage from '../data/Banner.json'
import HomeGallery from '../data/homeGallery.json'
//import Recent from '../data/homeRecent.json'
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import { colors } from '@mui/material'

export default function Home(){
    const [windowSize, setWindowSize] = useState([
        window.innerWidth,
        window.innerHeight,
    ]);
    
    useEffect(() => {
        const handleWindowResize = () => {
            setWindowSize([window.innerWidth, window.innerHeight]);
        };

        window.addEventListener('resize', handleWindowResize);

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    var percent = "30";
    var percent1 = "100"
    var imgpercent = "50";
    if(windowSize[0] < 768){
        percent = "90";
        imgpercent = "90";
        percent1 = "100";

    };
    
    return (
        <div>
            {/* <div className='h-screen w-full bg-black content-center' >
                <div className="pt-48 bg-black-800 text-gray-100" >
                    <h1 className="text-center text-6xl md:text-6xl font-medium mb-12 mx-8">IEEE STUDENT BRANCH</h1>
                    <h2 className="text-center text-6xl md:text-6xl font-medium mb-12 mx-8">
                    DAIICT
                    </h2>
                </div>  
            </div> */}

                        <Carousel autoPlay interval="4000" infiniteLoop centerMode centerSlidePercentage={percent1}
                        showIndicators={true} showThumbs={false} showStatus={false} className="" stopOnHover = {false}>
                            {bannerimage.map((photo) => (
                                <div className='mt-20 flex items-center  pb-10 justify-center mx-4  md:h-[700px]'>
                                    <img className='object-cover brightness-50 h-full rounded-xl object-contain md:object-cover' src={process.env.PUBLIC_URL + photo} />
                                    {/* <div class=" absolute mt-20 flex items-center pb-25 justify-center mx-4  md:h-[700px] z-20 opacity-200 bg-gradient-to-br from-zinc-800 to-zinc-700"></div> */}
                                    <h1 className='absolute md:pb-10 text-2xl md:text-6xl font-bold mb-20 mx-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'> IEEE STUDENT BRANCH</h1>
                                    <h1 className='absolute md:pt-24 pt-10 text-2xl md:text-6xl font-bold mb-12 mx-8 text-white drop-shadow-[0_1.2px_1.2px_rgba(0,0,0,0.8)]'> DAIICT</h1>
                                </div>
                            ))}
                        </Carousel>
            <div className="pt-16 pb-28 bg-gray-800 text-gray-100">
                <div className='mx-4 sm:mx-16 lg:mx-40 bg-gray-800 rounded-xl mb-16'>
                    <h2 className="flex items-center justify-center text-xl md:text-2xl font-medium mb-8">
                        Upcoming Event
                    </h2>
                    <div className='md:flex justify-center'>
                        <div className="flex justify-center items-center rounded-t-xl md:rounded-none md:rounded-l-xl"
                        style={{backgroundImage: ("url(" + process.env.PUBLIC_URL + '/images/events/manualrobotics22.jpeg' + ")")}}>
                            <div className="flex items-center h-full w-full backdrop-blur-xl rounded-t-xl md:rounded-none md:rounded-l-xl backdrop-brightness-75">
                                <img src={process.env.PUBLIC_URL + '/images/events/summerschool23.png' } 
                                className="backdrop-blur-xl backdrop-brightness-75 rounded-t-xl md:rounded-none md:rounded-l-xl object-contain w-full max-h-80"/>
                            </div>
                        </div>
                        {/* <div className='flex items-center justify-center md:rounded-none md:rounded-l-xl bg-gray-900 rounded-t-xl'>
                            <img src='images/events/manualrobotics22.jpeg' className='md:rounded-l-xl max-h-80 max-w-full' />
                        </div> */}
                        <div className='flex flex-col bg-gray-900 p-8 md:w-80 md:rounded rounded-b-xl max-h-80 d-none md:rounded-r-xl '>
                            <div className='grow'>
                                <h3 className='text-lg md:text-xl font-medium mb-3'>Summer School'23</h3>
                                <p className='mb-2'> A 4 day-long workshop which aims to familiarize students with a specific industry and teach them the basic skills they need.  </p>
                                <p>
                                    <b>Date : </b>3rd-4th and 10th-11th June 
                                </p>
                                <p>
                                    <b>Time : </b>10:00 AM
                                </p>

                            </div>
                            <div className='mt-4 self-end w-full'>
                                <Button onClick={(e) => { window.location.href='http://ieee.daiict.ac.in/ss23/';}} variant="contained" style={{textTransform: 'none'} }>Register Now</Button>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='mx-4 sm:mx-16 lg:mx-40 my-12'>
                    <div className="space-y-4 mb-2">
                        <div className="bg-gray-900 rounded-xl p-8">
                            <h2 className="flex items-center justify-center text-xl md:text-2xl font-medium mb-3">
                                {Content1.title}
                            </h2>
                            {Content1.content.map((para) => (
                                <p className='text-center mb-2'>{para}</p>
                            ))}
                        </div>

                        <Carousel autoPlay interval="3000" infiniteLoop centerMode centerSlidePercentage={imgpercent}
                        showIndicators={false} showThumbs={false} showStatus={false} className="">
                            {HomeGallery.map((photo) => (
                                <div className='flex items-center justify-center mx-4 h-full md:h-80'>
                                    <img className='h-full rounded-xl object-contain md:object-cover' src={process.env.PUBLIC_URL + '/images' + photo} />
                                </div>
                            ))}
                        </Carousel>

                        <div className="bg-gray-900 rounded-xl p-8">
                            <h2 className="flex items-center justify-center text-xl md:text-2xl font-medium mb-3">
                                {Content2.title}
                            </h2>
                            {Content2.content.map((para) => (
                                <p className='text-center mb-2'>{para}</p>
                            ))}
                        </div>

                        {List.map((data) => (
                            <div className="bg-gray-900 rounded-xl p-8">
                                <h2 className="flex items-center justify-center text-xl md:text-2xl font-medium mb-3">
                                    {data.title}
                                </h2>
                                <ul className='list-disc ml-4'>
                                    {data.list.map((point) => (
                                        <li className='mb-2'>{point}</li>
                                    ))}
                                </ul>     
                            </div>
                        ))}
                    </div>
                </div>

                <div className='mx-4 sm:mx-16 lg:mx-40 bg-gray-800 rounded-xl my-12'>
                    <h2 className="flex items-center justify-center text-xl md:text-2xl font-medium mb-8">
                        Achievements
                    </h2>
                    <Carousel autoPlay interval="3000" infiniteLoop centerMode centerSlidePercentage={percent}
                        showIndicators={false} showThumbs={false} showStatus={false}>
                        {Achievements.map((achievement) => (
                            <div className='flex items-center text-center rounded-xl mx-1 h-full p-6 bg-gray-900'>
                                {achievement}
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
        </div>
        
    )
}