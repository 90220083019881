import ChapterData from '../data/chapters.json';

export default function Chapters(){
    return (
        <div className="py-28 bg-gray-800 text-gray-100 mx-0">
            <h1 className="text-center text-2xl md:text-4xl font-medium mb-12 mx-8">Chapters and SIGs</h1>
            {ChapterData.map((chapter) => (
                <div>
                    <div id={chapter.id} className="relative bottom-24"></div>
                    <div className="mb-16 grid grid-cols-1 md:grid md:grid-cols-3 mx-8 sm:mx-16 lg:mx-40 bg-gray-900 rounded-xl justify-center">
                        <div className="flex justify-center items-center bg-white rounded-t-xl md:rounded-none md:rounded-l-xl md:p-2">
                            <img className="p-5" src={process.env.PUBLIC_URL + chapter.src}/>
                        </div>
                        <div className="m-10 sm:col-span-2">
                            <h1 className="flex text-center text-xl md:text-2xl font-medium mb-4">
                                {chapter.name}
                            </h1>
                            {chapter.content.map((para) => (
                                <p className='flex mb-2'>
                                    {para}
                                </p>
                            ))}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    )
}