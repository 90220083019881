import {FiPhoneCall} from 'react-icons/fi'
import {MdOutlineEmail} from 'react-icons/md'
import {BsPinMapFill} from 'react-icons/bs'

export default function Contact(){
    return (
        <div className="py-28 bg-gray-800 text-gray-100 mx-0">
            <h1 className="text-center text-2xl md:text-4xl font-medium mb-12 mx-8">Reach Us</h1>
            <div className='md:grid md:grid-cols-3 mx-8 sm:mx-16 lg:mx-40 gap-3'>
                <div className='bg-gray-900 p-6 rounded-xl mb-3'>
                    <h2 className="flex items-center justify-center text-xl font-medium mb-2">
                        Address <BsPinMapFill className='mx-2'/>
                    </h2>
                    <p className="text-center">
                        IEEE Room, DA-IICT, Reliance Cross Rd., Gandhinagar, Gujarat - 382007
                    </p>
                </div>
                <div className='bg-gray-900 p-6 rounded-xl mb-3'>
                    <h2 className="flex items-center justify-center text-xl font-medium mb-2">
                        Phone <FiPhoneCall className='mx-2'/>
                    </h2>
                    <p className="text-center">
                        Darshan Kheni, Chairperson <br/> 
                        <a href="tel:+91 99999 99999" className="hover:text-cyan-400">+91 63566 05363</a>
                    </p>
                </div>
                <div className='bg-gray-900 p-6 rounded-xl mb-3'>
                    <h2 className="flex items-center justify-center text-xl font-medium mb-2">
                        Email <MdOutlineEmail className='mx-2'/>
                    </h2>
                    <p className="text-center">
                        <a href="ieee@daiict.ac.in" className="hover:text-cyan-400">ieee@daiict.ac.in</a><br/>
                        <a href="ieee@daiict.ac.in hover:text-blue" className="hover:text-cyan-400">ieeesbdaiict@gmail.com</a>
                    </p>
                </div>
            </div>
            <div className="flex mx-8 sm:mx-16 lg:mx-40 0 mb-6">
                <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1833.7465606816859!2d72.629205!3d23.188693!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xc54de484f986b1fa!2sDA-IICT!5e0!3m2!1sen!2sin!4v1674130963672!5m2!1sen!2sin"
                    className="w-full rounded-xl m-0"
                    height="360"
                    allowFullScreen="true" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                </iframe>
            </div>
        </div>
    )
}