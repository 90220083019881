import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Chapters from './components/Chapters.js';
import Grant from './components/grantapp.js';
import Navbar from './components/Navbar.js';
import Footer from './components/Footer.js';
import MyGallery from './components/Gallery.js';
import Contact from './components/Contact.js';
import Members from './components/Members.js';
import Execomm from './components/Execomm.js';
import Events from './components/Events.js';
import History from './components/History.js';
import Home from './components/Home.js';


import { useEffect } from "react";
import { useLocation } from "react-router-dom";



function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    // "document.documentElement.scrollTo" is the magic for React Router Dom v6
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant", // Optional if you want to skip the scrolling animation
    });
  }, [pathname]);

  return null;
}



export default function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
          <Route exact path='/ieee2023' element={<Home />} />
          <Route exact path='/' element={<Home />} />
          <Route exact path='/events' element={<Events />} />
          <Route exact path='/gallery' element={<MyGallery />} />
          <Route exact path='/members' element={<Members />} />
          <Route exact path='/execomm' element={<Execomm />} />
          <Route exact path='/chapters' element={<Chapters />} />
          <Route exact path='/contact' element={<Contact />} />
          <Route exact path='/history' element={<History />} />
          <Route exact path='/grant-application' element={<Grant />} />
      </Routes>
      <Footer />
    </Router>
  );
}

