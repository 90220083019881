import React from 'react'
import Gallery from 'react-photo-gallery';
// import photos from '../data/photos.json'

const photos = 
[
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/1.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/2.jpg`,
        "width": 1,
        "height": 1
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/3.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/4.jpg`,
        "width": 1,
        "height": 1
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/5.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/6.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/7.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/8.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/9.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/10.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/11.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/12.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/13.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/14.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/15.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/16.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/17.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/18.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/19.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/20.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/21.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/22.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/23.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/24.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/25.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/26.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/27.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/28.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/29.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/30.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/31.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/32.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/33.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/34.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/35.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/36.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/37.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/38.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/39.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/40.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/41.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/42.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/43.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/44.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/45.jpg`,
        "width": 16,
        "height": 9
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/46.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/47.jpg`,
        "width": 4,
        "height": 3
    },

    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/48.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/49.jpg`,
        "width": 4,
        "height": 3
    },
    {
        "src":`${process.env.PUBLIC_URL}/images/gallery/50.jpg`,
        "width": 4,
        "height": 3
    }
]

export default function MyGallery() {
    return (
        <div className="py-28 pb-20 bg-gray-800 text-gray-100 mx-0">
            <h1 className="text-center text-2xl md:text-4xl font-medium mb-12 mx-8">Gallery</h1>
            <div className="mx-8 sm:mx-16 lg:mx-24">
                <Gallery photos={photos} />
            </div>
        </div>
    )
}
