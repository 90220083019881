import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import { timelineItemClasses } from '@mui/lab/TimelineItem';
import { useState, useEffect } from 'react';

import EventData from "../data/events.json";


export default function Events() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  const [windowSize, setWindowSize] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize([window.innerWidth, window.innerHeight]);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });


  const pos = (windowSize[0] > 768 ? 'alternate' : 'right');

  return (
    <div  className='py-28 bg-gray-800 text-gray-100 mx-0'>
      <h1 className="text-center text-2xl md:text-4xl font-medium mb-6 mx-8">Events</h1>
      <Box>
        <TabContext value={value}>
        <Box>
        <TabList value={value} onChange={handleChange} centered textColor='inherit'>
          {EventData.map((yearData, i) => (
            <Tab label={yearData.year} value={i} />
          ))}
        </TabList>
        </Box>
        {EventData.map((yearData, i) => (
            <TabPanel value={i}>
                <div className='py-3 bg-gray-800 text-gray-100 mx-0'>
                    <Timeline position={pos} className='md:mx-16 lg:mx-24' 
                        sx={{
                            [`& .${timelineItemClasses.root}:before`]: {
                              flex: 0,
                              padding: 0,
                            },
                          }}>
                        {yearData.events.map((event, i) => (
                        <TimelineItem>
                            <TimelineOppositeContent className="hidden md:block">
                                <h2 className='text-xl font-bold'> 
                                    {event.date}
                                </h2>
                            </TimelineOppositeContent>
                            <TimelineSeparator>
                                <TimelineDot variant="outlined" color='primary'/>
                                <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>
                                <h2 className='text-xl font-bold mb-4 md:hidden'> 
                                    {event.date}
                                </h2>
                                <div className="mb-16 lg:flex bg-gray-900 rounded-xl text-left">
                                    <div className="lg:hidden flex justify-center items-center rounded-t-xl px-6 pt-6">
                                        <img className="w-64" src={process.env.PUBLIC_URL + event.src} />
                                    </div>
                                {
                                    (i%2 === 0) && 
                                    <div className="hidden lg:flex w-1/3 justify-center items-center py-6 pl-6">
                                        <img className="w-64" src={process.env.PUBLIC_URL + event.src} />
                                    </div>
                                } 
                                    <div className="flex flex-col lg:w-2/3 p-6">
                                        <h1 className="text-xl font-bold mb-1">
                                            {event.title}
                                        </h1>
                                        <p className='flex mb-3'>
                                            {event.desc}
                                        </p>
                                        {
                                          event.href && 
                                          <a href={event.href} className='w-fit text-sm p-1 px-2 bg-transparent hover:bg-blue-500 text-blue-400 hover:text-white border border-blue-500 hover:border-transparent rounded'>
                                              View Details
                                          </a>
                                        }
                                    </div>
                                {
                                    (i%2 === 1) && 
                                    <div className="hidden lg:flex w-1/3 justify-center items-center rounded-t-xl rounded-none rounded-l-xl py-6 pr-6">
                                        <img className="w-64" src={process.env.PUBLIC_URL + event.src} />
                                    </div>
                                } 
                                </div>
                            </TimelineContent>
                        </TimelineItem>
                        ))}
                    </Timeline>
                </div>
            </TabPanel>
        ))}
        </TabContext>
      </Box>
    </div>
  );
}